let url = window.location.hostname

const Env = ()=>{
    if(url == 'sso.hsb1234.com'){
        return 'pro'
    }else if(url.indexOf('pre-') != -1){
        return 'pre'
    }else if(url.indexOf('test-') != -1){
        return 'test'
    }else{
        return 'dev'
    }
}
console.log(Env())

export default Env